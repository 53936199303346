import React, { useState, useEffect } from 'react';

export interface FloorType {
  name: string;
  companies: {
    name: string;
    logo: string;
  }[];
  index: number;
}

export interface FloorPageType {
  logo?: string;
  bgImages: string[]; // Updated to accept an array of images for dynamic change
  floors?: FloorType[];
  text?: string;
}

export const FloorPage: React.FC<FloorPageType> = ({
  bgImages,
  logo,
  floors,
  text,
}) => {
  const [currentImage, setCurrentImage] = useState(bgImages[0]); // Default to the first image

  // Effect to update the background image every hour
  useEffect(() => {
    const updateImage = () => {
      setCurrentImage((prevImage) => {
        const currentIndex = bgImages.indexOf(prevImage);
        const nextIndex = (currentIndex + 1) % bgImages.length; // Cycle back to the start
        return bgImages[nextIndex];
      });
    };

    const interval = setInterval(updateImage, 60 * 60 * 1000); // Update every hour
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [bgImages]);

  return (
    <div key={currentImage} className="each-slide-effect">
      <div
        style={{
          background: `url(${currentImage})`,
          zIndex: 1,
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          padding: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: 600, height: 'auto', marginTop: 40 }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div
              style={{
                width: '30%',
                height: 'auto',
                backgroundColor: 'rgba(255,255,255, 0.8)',
                borderRadius: 15,
                margin: 10,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  backgroundColor: '#00AAA7',
                  width: '100%',
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                }}
              >
                <h3 style={{ color: 'white' }}>{floors?.[0]?.name}</h3>
              </div>

              {floors?.[0]?.companies?.map((company, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      height: 35,
                      alignItems: 'center',
                      marginLeft: 5,
                      marginRight: 5,
                      padding: 5,
                      justifyContent: 'center',
                      borderBottomStyle: 'solid',
                      borderBottomWidth:
                        i === floors?.[0]?.companies?.length - 1 ? 0 : 0.5,
                    }}
                  >
                    <img src={company?.logo} alt="" style={{ height: 35 }} />
                    <p
                      style={{
                        marginLeft: 10,
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}
                    >
                      {company.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {floors?.slice(1).map((floor, fi) => {
              return (
                <div
                  key={fi}
                  style={{
                    width: '30%',
                    height: 'auto',
                    backgroundColor: 'rgba(255,255,255, 0.8)',
                    borderRadius: 15,
                    margin: 10,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#00AAA7',
                      width: '100%',
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                    }}
                  >
                    <h3 style={{ color: 'white' }}>{floor.name}</h3>
                  </div>

                  {floor?.companies?.map((company, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: 'flex',
                          height: 35,
                          alignItems: 'center',
                          marginLeft: 5,
                          marginRight: 5,
                          padding: 5,
                          justifyContent: 'center',
                          borderBottomStyle: 'solid',
                          borderBottomWidth:
                            i === floor?.companies?.length - 1 ? 0 : 0.5,
                        }}
                      >
                        <img
                          src={company?.logo}
                          alt=""
                          style={{ height: 35 }}
                        />
                        <p
                          style={{
                            marginLeft: 10,
                            fontSize: 16,
                            fontWeight: 'bold',
                          }}
                        >
                          {company.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
