import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import kompetanse from '../assets/images/Kompetansehuset.jpg';
import kplogo from '../assets/images/kplogo.png';
import flex from '../assets/images/flexboks.png';
import flexBg from '../assets/images/flexBg.jpg';
import flexWhite from '../assets/images/flex_hvit.png';
import fjernvarme from '../assets/images/fjernvarme.png';
import hevBg from '../assets/images/hevBg.jpg';
import hev from '../assets/images/hev.png';
import barnehus from '../assets/images/statens_barnehus.png';

import { FloorPage } from './Page';
import { ReactElement } from 'react';
import { CompanyPage } from './CompanyPage';

const FLOORS = [
  {
    name: '1. ETASJE',
    companies: [
      {
        name: 'Statens Barnehus',
        logo: barnehus,
      },
      {
        name: '',
        logo: 'https://aider.no/wp-content/uploads/logo-blue-1.svg',
      },
      {
        name: '',
        logo: 'https://iteam.no/wp-content/uploads/2021/11/Logo-positiv_v2.svg',
      },
    ],
    index: 0,
  },
  {
    name: '2. ETASJE',
    companies: [
      {
        name: 'Nofi Tromsø AS',
        logo: 'https://www.nofitromso.no/img/nofitromso/Forside/NOFI-Troms%C3%B8-logo_trans.png',
      },
      {
        name: 'Konfliktrådet i Helgeland',
        logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFk9Ys7i8BCPKSJuwh9QwBVcu74SotZ6fSXw&s',
      },
      {
        name: 'Ing. Terje Jakobsen AS',
        logo: '',
      },
      {
        name: 'Mosjøen Fjernvarme',
        logo: fjernvarme,
      },
      {
        name: 'CableCom AS',
        logo: '',
      },
      {
        name: 'Øynor Consult AS',
        logo: '',
      },
    ],
    index: 0,
  },
  {
    name: '3. ETASJE',
    companies: [
      {
        name: 'Nord universitet',
        logo: 'https://pbs.twimg.com/profile_images/1537353783969300480/m4EK713Q_400x400.png',
      },
      {
        name: 'If forsikring Mosjøen',
        logo: 'https://s3-eu-west-1.amazonaws.com/tpd/logos/58c956220000ff00059e8822/0x0.png',
      },
      {
        name: 'Price Waterhouse Coopers',
        logo: 'https://telecomnancy.univ-lorraine.fr/wp-content/uploads/2022/10/PwC-PricewaterhouseCoopers-Symbol-PNG.png',
      },
      {
        name: 'Akantus AS',
        logo: '',
      },
      {
        name: 'Midt Norsk Industri Consult AS',
        logo: '',
      },
      {
        name: ' North Inspection AS',
        logo: 'https://www.northinspection.no/uploads/rEtfSuMy/MkXH48kEk3ZMD2TAP__w1500__msi___png.png',
      },
    ],
    index: 0,
  },
  {
    name: '4. ETASJE',
    companies: [
      { name: '', logo: flex },
      { name: '', logo: hev },
    ],
    index: 0,
  },
];

const bgImages = [
  kompetanse,
  'https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg',
  'https://gratisography.com/wp-content/uploads/2024/11/gratisography-augmented-reality-800x525.jpg',
];

export const SlideShow = () => {
  const pages: ReactElement[] = [
    <FloorPage bgImages={bgImages} logo={kplogo} floors={FLOORS} />,
    <CompanyPage
      name="North Inspection AS"
      // bgImage={
      //   'https://aider.no/wp-content/uploads/AIDER-SKIEN-116929-1-scaled.jpg'
      // }
      imageStyle={{
        background: `linear-gradient(
      rgba(0, 0, 0, .7), 
      rgba(0, 0, 0, .7)
    ), url(${'https://www.northinspection.no/uploads/dNb9zuOO/welding-work-with-metal-construction-busy-metal-factory_522_860__msi___jpg.jpg'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        padding: 20,
        justifyContent: 'center',
        flexDirection: 'row',
      }}
      title="Velkommen til North Inspection, Vår kunnskap - Din trygghet"
      description="Vi er en ledende leverandør av NDT / ikke-destruktiv testing, inspeksjon og sveisetekniske tjenester i Nord-Norge"
      logo={
        'https://www.northinspection.no/uploads/rEtfSuMy/MkXH48kEk3ZMD2TAP__w1500__msi___png.png'
      }
      titleColor="#005ba8"
      descColor="#fff"
      phone="+47 47 78 80 00"
      hours="Man - Fre, 08:00 - 16:00"
      email="post@northinspection.no"
      floor="3. etasje"
      floors={FLOORS}
    />,
    <CompanyPage
      name="Flexboks"
      bgImage={flexBg}
      title="Teknologi og Utvikling"
      description="Flexboks er et teknologiselskap som utvikler egne tjenester, samt innovative løsninger for ulike aktører med fokus på bærekraft. Vår ambisjon er å skape Nord-Norges største teknologi og innovasjonsmiljø."
      logo={flexWhite}
      titleColor="#f9ac46"
      descColor="#fff"
      email="post@flexboks.com"
      phone="+ 47 99357299"
      hours="Man - Fre, 08:00 - 16:00"
      floor="4. etasje"
      floors={FLOORS}
    />,
    <CompanyPage
      name="HEV"
      bgImage={hevBg}
      title="Event og Profilering"
      description="HEV startet som en høytsvevende idé av en gruppe venner i 2007. Siden da har vi vokst og utviklet oss til å bli en av de ledende aktørene i bransjen. Vi jobber i dag for å HEVe privat og offentlig næringsliv over hele landet, med Helgeland som kjerneområde."
      logo={
        'https://i0.wp.com/helgelandevent.no/wp-content/uploads/2020/11/Helgelandevent_main.png?resize=1000%2C561&ssl=1'
      }
      titleColor="#f45500"
      descColor="#fff"
      email="post@helgelandevent.no"
      phone="(+47) 989 01 242"
      hours="Man - Fre, 08:00 - 16:00"
      floor="4. etasje"
      floors={FLOORS}
    />,
    <CompanyPage
      name="Aider"
      imageStyle={{
        background: `linear-gradient(
          rgba(0, 0, 0, .8), 
          rgba(0, 0, 0, .8)
        ), url(${'https://aider.no/wp-content/uploads/AIDER-SKIEN-116929-1-scaled.jpg'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        padding: 20,
        justifyContent: 'center',
        flexDirection: 'row',
      }}
      title="Aider regnskap, rådgivning og teknologi."
      description="Aider tilbyr morgendagens tjenester innen regnskap, rådgivning og teknologi. Vi tilbyr skybaserte, skalerbare løsninger tilpasset din virksomhets behov."
      logo={'https://aider.no/wp-content/uploads/logo-blue-1.svg'}
      titleColor="#108299"
      descColor="#fff"
      phone="+ 47 99 52 66 53"
      hours="Man - Fre, 08:00 - 16:00"
      floor="1. etasje"
      floors={FLOORS}
    />,
  ];

  return (
    <Slide
      arrows={false}
      indicators={false}
      duration={30000}
      pauseOnHover={false}
      canSwipe={false}
    >
      {pages.map((page) => {
        return page;
      })}
    </Slide>
  );
};
